import { DiscreteCondition } from '@discngine/moosa-models';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import truncate from 'lodash/truncate';
import { useCallback, useMemo } from 'react';

import { DiscreteSlicedBar, selectBarComponentProps } from './DicreteSlicedBar';
import style from './DiscreteHistogram.module.less';
import { EmptyHistogramNotification } from './EmptyHistogramNotification';
import { MARGIN, NUM_TICKS_X, NUM_TICKS_Y, tickLabelProps } from './histogramHelpers';
import { DiscreteHistogramData } from './types';

interface Props {
  condition?: DiscreteCondition;
  onChange?: (condition: DiscreteCondition) => void;
  data: DiscreteHistogramData;
  width: number;
  height: number;
}
export const DiscreteHistogram = (props: Props) => {
  const { condition, onChange, height, width, data } = props;

  const yValueMax = useMemo(() => {
    return Math.max(...data.bars.map(({ y }) => y.reduce((sum, y) => sum + y, 0)));
  }, [data]);

  const xValues = useMemo(() => {
    const conditionValues = condition ? condition.values.map((x) => x.value) : [];
    const datasetMissingValues = data.bars
      .filter((bar) => conditionValues.indexOf(bar.x) === -1)
      .map((bar) => bar.x);

    return [...conditionValues, ...datasetMissingValues];
  }, [condition, data.bars]);

  const yScale = useMemo(() => {
    return scaleLinear({
      range: [MARGIN.top, height - MARGIN.bottom],
      domain: [yValueMax, 0],
    });
  }, [height, yValueMax]);

  const xScale = useMemo(() => {
    return scaleBand({
      range: [MARGIN.left, width - MARGIN.right],
      domain: xValues,
      padding: 0.2,
    });
  }, [width, xValues]);

  const conditionMap = useMemo(() => {
    return condition
      ? condition.values.reduce<Record<string, boolean>>((acc, item) => {
          acc[item.value] = item.isSelected;

          return acc;
        }, {})
      : {};
  }, [condition]);

  const onBarClick = useCallback(
    (value: string) => {
      if (!condition || !onChange) return;

      const isSelected = !!conditionMap?.[value];

      const newCondition = {
        ...condition,
        values: condition.values.map((x) => {
          if (x.value === value) {
            return { ...x, isSelected: !isSelected };
          }

          return x;
        }),
      };

      onChange(newCondition);
    },
    [condition, conditionMap, onChange]
  );

  const yMax = height - MARGIN.bottom;
  const selectBarComponent = useMemo(
    () =>
      ({ barWidth, barX, bar }: selectBarComponentProps) => {
        return (
          <rect
            cursor="pointer"
            fill={conditionMap[bar.x] ? 'transparent' : 'rgba(255,255,255,.3)'}
            height={yMax - MARGIN.top}
            width={barWidth}
            x={barX}
            y={MARGIN.top}
            onClick={() => onBarClick(bar.x)}
          />
        );
      },
    [conditionMap, onBarClick, yMax]
  );

  return (
    <svg
      className={style.plot}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {yValueMax <= 0 && <EmptyHistogramNotification height={height} width={width} />}

      {yValueMax > 0 && (
        <Group>
          <Group>
            <DiscreteSlicedBar
              component={condition ? selectBarComponent : undefined}
              data={data}
              xScale={xScale}
              yScale={yScale}
            ></DiscreteSlicedBar>
          </Group>

          <Group left={MARGIN.left}>
            <AxisLeft hideZero numTicks={NUM_TICKS_Y} scale={yScale} />
          </Group>

          <Group top={height - MARGIN.bottom}>
            <AxisBottom
              numTicks={NUM_TICKS_X}
              scale={xScale}
              tickComponent={({ formattedValue, ...props }) => (
                <text
                  {...props}
                  dy="0.45em"
                  transform={`rotate(${props.angle}, ${props.x}, ${props.y})`}
                >
                  {formattedValue && formattedValue?.length > 7 && (
                    <title>{formattedValue}</title>
                  )}
                  {truncate(String(formattedValue), { length: 7 })}
                </text>
              )}
              tickLabelProps={tickLabelProps}
            />
          </Group>
        </Group>
      )}
    </svg>
  );
};
