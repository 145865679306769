import { DesirabilityFunctionType } from '@discngine/moosa-models';

import {
  CustomFunctionIcon,
  DiscreteFunctionIcon,
  LinearFunctionIcon,
  LogarithmicFunctionIcon,
  RectangularFunctionIcon,
  SigmoidFunctionIcon,
  TriangularFunctionIcon,
  UnitStepFunctionIcon,
} from '@discngine/moosa-shared-components-gen';

export const getIcon = (type: DesirabilityFunctionType): JSX.Element => {
  switch (type) {
    case DesirabilityFunctionType.linear:
      return <LinearFunctionIcon />;
    case DesirabilityFunctionType.triangular:
      return <TriangularFunctionIcon />;
    case DesirabilityFunctionType.rectangular:
      return <RectangularFunctionIcon />;
    case DesirabilityFunctionType.logarithmic:
      return <LogarithmicFunctionIcon />;
    case DesirabilityFunctionType.sigmoid:
      return <SigmoidFunctionIcon />;
    case DesirabilityFunctionType.unitStep:
      return <UnitStepFunctionIcon />;
    case DesirabilityFunctionType.discrete:
      return <DiscreteFunctionIcon />;
    case DesirabilityFunctionType.custom:
      return <CustomFunctionIcon />;

    default:
      ((x: never) => {
        throw new Error('Unexpected desirability function type');
      })(type);
  }
};
